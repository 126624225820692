/* eslint-disable no-new */

/* global $, google, Backbone */

export const StoreLocatorView = Backbone.View.extend({
  initialize: function () {
    const $el = this.$el

    if (!$el.length) {
      return
    }

    this.$form = $el.find('form')
    this.$results = $el.find('[data-locator-results]')

    this.$form.find('input[name=city]').on('focus', this._getLocation())

    this.$form.on('submit', this.submit.bind(this))
    this._prefill()
  },

  submit: function () {
    const url = this.$form.attr('action')
    this.$form.find('[type="submit"]').blur()
    this._updateParams()

    $.get(url, {
      city: this._getCity(),
      country: this._getCountry(),
      radius: this._getRadius()
    }).then(function (data) {
      this.$results.show()
      this.$results.html(data)
      this.drawMap()
    }.bind(this))
    return false
  },

  _getLocation: function () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const localLatitude = position.coords.latitude
        const localLongitude = position.coords.longitude
        const latlng = {
          lat: localLatitude,
          lng: localLongitude
        }
        const geocoder = new google.maps.Geocoder()

        geocoder
          .geocode({ location: latlng })
          .then((response) => {
            if (response.results[0]) {
              const address = response.results[0].address_components
              for (let p = address.length - 1; p >= 0; p--) {
                if (address[p].types.indexOf('locality') !== -1) {
                  document.getElementById('location').value = address[p].long_name
                }
              }
            } else {
              window.alert('No results found')
            }
          })
      })
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  },
  drawMap: function () {
    const canvas = document.getElementById('map-canvas')
    const lat = canvas.getAttribute('store-lat')
    const lon = canvas.getAttribute('store-lon')
    const map = new google.maps.Map(document.getElementById('map-canvas'), {
      center: new google.maps.LatLng(lat, lon),
      zoom: 15
    })

    this._drawMarkers(map)
  },

  _drawMarkers: function (map) {
    const stores = this._getStores()
    const positions = []
    const brauhausIcon = '/_Resources/Static/Packages/Brauhaus.RoseDe/Images/layout/brauhaus-icon.svg'
    if (stores) {
      for (let i = stores.length - 1; i >= 0; i--) {
        const store = stores[i]
        new google.maps.Marker({
          position: new google.maps.LatLng(store.latitude, store.longitude),
          map: map,
          icon: {
            url: brauhausIcon,
            scaledSize: {
              width: 35,
              height: 35
            }
          },
          content: brauhausIcon,
          label: ({
            text: '' + (i + 1),
            className: 'store-marker'
          }),
          title: store.company,
          clickable: false
        })
      }
      stores.forEach(function (store) {
        positions.push(new google.maps.LatLng(store.latitude, store.longitude))
      })
      this._zoomToMarkers(map, positions, 5)
    }
  },

  _zoomToMarkers: function (map, positions, count) {
    const bounds = new google.maps.LatLngBounds()
    positions.forEach(function (position, index) {
      if (index < count) {
        bounds.extend(position)
      }
    })
    map.fitBounds(bounds)
  },

  _prefill: function () {
    const city = this._getParam('city')
    const country = this._getParam('country')

    if (city && country) {
      this.$form.find('input[name=city]').val(city)
      this.$form.find('select[name=country]').val(country)
      this.$form.submit()
    }
  },

  _getParam: function (param) {
    const hash = window.location.hash
    const re = new RegExp(param + '=([äöüÄÖÜß\\w]+)')
    const match = hash.match(re)
    if (match) {
      return match[1]
    }
  },

  _updateParams: function () {
    window.location.hash = '?city=' + this._getCity() +
    '&country=' + this._getCountry()
  },

  _getCountry: function () {
    return this.$form.find('input[name=country]').val()
  },

  _getCity: function () {
    return this.$form.find('input[name=city]').val()
  },

  _getRadius: function () {
    return this.$form.find('select[name=radius]').val()
  },

  _getStores: function () {
    return window.stores
  }
})
