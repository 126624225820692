import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'
import { StoreLocatorView } from './lib/store-locator.js'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  new StoreLocatorView({ el: '#store-locator' })

  $('#age-agree-btn').click(function () {
    // set sessionStorage on click
    localStorage.setItem('hide', true)
    $('#age').hide()
  })

  if (localStorage.getItem('hide')) {
    // When sessionStorage is set hide the modal
    $('#age').hide()
  } else {
    $('#age').show()
  }
})
